<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pa-8">
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" class="pa-8">
        <v-skeleton-loader
          type="article"
          class="padding-bottom"
        ></v-skeleton-loader>
        <v-skeleton-loader type="sentences" class="pl-4"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph" class="pl-4"></v-skeleton-loader>
        <br />
        <v-skeleton-loader type="sentences" class="pl-4"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph" class="pl-4"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pa-8">
        <v-skeleton-loader
          type="article"
          class="padding-bottom"
        ></v-skeleton-loader>
        <v-skeleton-loader type="sentences" class="pl-4"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph" class="pl-4"></v-skeleton-loader>
        <br />
        <v-skeleton-loader type="sentences" class="pl-4"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph" class="pl-4"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" class="pa-8">
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pa-8">
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" class="pa-8">
        <v-skeleton-loader
          type="article"
          class="padding-bottom"
        ></v-skeleton-loader>
        <v-skeleton-loader type="sentences" class="pl-4"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph" class="pl-4"></v-skeleton-loader>
        <br />
        <v-skeleton-loader type="sentences" class="pl-4"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph" class="pl-4"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "website-loader",
  data() {
    return {};
  }
};
</script>

<style scoped>
.padding-bottom ::v-deep .v-skeleton-loader__paragraph {
  padding-bottom: 0px;
}
</style>
