<template>
  <div>
    <v-app-bar
      :color="transparent && !loading ? 'transparent' : 'secondary'"
      dark
      fixed
      class="navbar-position"
      :flat="transparent"
    >
      <v-toolbar-title class="title">Venusa Terapeuta</v-toolbar-title>
      <v-spacer />

      <v-tabs
        right
        v-if="$vuetify.breakpoint.smAndUp"
        v-model="currentNavbarSection"
      >
        <v-tab>Home</v-tab>
        <v-tab>Terapias</v-tab>
        <v-tab>Cartelera</v-tab>
      </v-tabs>

      <v-menu v-else offset-y>
        <template v-slot:activator="{ on }">
          <v-btn dark class="mt-0" large icon v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="currentNavbarSection">
            <v-list-item>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Terapias</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Cartelera</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      transparent: true
    };
  },
  props: {
    currentSection: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleScroll() {
      this.transparent = window.scrollY < 20 ? true : false;
    }
  },
  computed: {
    currentNavbarSection: {
      //getter
      get: function() {
        return this.currentSection;
      },
      // setter
      set: function(newValue) {
        this.$emit("changeSection", newValue);
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style scoped>
.title {
  overflow: inherit;
}
.navbar-position {
  z-index: 10;
}
</style>
